import React,{Component, PureComponent} from 'react';
import "./../../App.css"
import {Paper, Icon} from "@material-ui/core"
import {FlexnavProjectionState} from "hubup-map-engine";
import {DirectionHelper} from "../../DirectionHelper";
import {Tools} from "../../Tools";

const PAST_STOP_HEIGHT = 36;
const NEXT_STOP_HEIGHT = 70;

export default class DirectionHeader extends PureComponent {

    constructor(props) {
        super(props);

        this.state={
            nextDirectionPE:null,
            distanceToNextDirection:null,
            dangerZoneLE:null,

            thermometerStops:[],
        }
    }

    _projectionUpdateSubscription:any;

    isDesktop:any = window.innerWidth >= 800;
    hideThermometer:boolean = false;

    componentDidMount(){
        this._projectionUpdateSubscription = global.emitter.addListener("projectionUpdate",(projection)=>{
            this.handleProjection(projection)
        })

        let urlParams = new URLSearchParams(window.location.search);
        let thermometer = urlParams.get("thermometer");
        if(thermometer && thermometer === "disabled"){
            this.hideThermometer = true
        }
    }

    componentWillUnmount(){
        if(this._projectionUpdateSubscription){this.this._projectionUpdateSubscription.remove()}
    }

    handleProjection(projection:FlexnavProjectionState){

        if(!projection){
            return
        }

        let nextDirectionPE = null;
        let distanceToNextDirection = null;
        let dangerZoneLE = null;


        if(projection.nextDirectionPE){
            nextDirectionPE = projection.nextDirectionPE
        }
        if(projection.distanceToNextDirection){
            distanceToNextDirection = projection.distanceToNextDirection
        }
        if(projection.isInDangerZone || projection.isReachingDangerZone){
            if(projection.nextDangerZoneLE){
                dangerZoneLE = projection.nextDangerZoneLE;
            }
        }


        this.setState({
            nextDirectionPE:nextDirectionPE,
            distanceToNextDirection:distanceToNextDirection,
            dangerZoneLE:dangerZoneLE,

            thermometerStops:this.getThermometerStops(projection),
        },()=>{
            this.scrollStops();
        })
    }

    render(){
        if(!this.state.nextDirectionPE){return null}

        return (
            <div style={{maxWidth:"calc(100vw - 16px)", width:500, height:"100%", paddingTop:8, paddingLeft:8, paddingBottom:8,
            display:"flex", flexDirection:"column"}}>
                <Paper style={{height:60, borderRadius:8, backgroundColor:"#1079FC", width:"100%" , pointerEvents:"all", marginBottom:this.state.dangerZoneLE ? 0 :8,
                    borderBottomLeftRadius:this.state.dangerZoneLE ? 0 : 8, borderBottomRightRadius:this.state.dangerZoneLE ? 0 : 8}}>

                    <div style={{height:"100%", width:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:'center'}}>

                        <div style={{height:70, width:70, display:"flex", justifyContent:"center", alignItems:"center", padding:4}}>
                            <img alt={"picto"} style={{height:'100%', width:'100%'}} src={DirectionHelper.getPictureSrc(this.state.nextDirectionPE.direction.position)}/>
                        </div>
                        <div style={{width:"calc(100% - 60px)", height:60, display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:'center', padding:4}}>
                            <p style={{color:"#FFFFFF", fontSize:20, fontWeight:"bold", textAlign:"left", margin:0}}>
                                {Tools.getPrettyDistance(this.state.distanceToNextDirection)}
                            </p>
                            <p style={{color:"#FFFFFF", fontSize:16, fontWeight:"normal", textAlign:"left", margin:0, marginTop:2}}>
                                {this.state.nextDirectionPE.direction.textToDisplay}
                            </p>
                        </div>
                    </div>
                </Paper>

                {this.state.dangerZoneLE &&
                    <Paper style={{height:50, borderRadius:8, backgroundColor:"#fc8a10", width:"100%" , pointerEvents:"all", marginBottom:8,
                    borderTopLeftRadius:0, borderTopRightRadius:0, borderTop:"1px solid #555555"}}>

                        <div style={{height:"100%", width:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:'center'}}>

                            <div style={{height:70, width:70, display:"flex", justifyContent:"center", alignItems:"center", padding:4}}>
                                <Icon style={{color:"#FFFFFF", fontSize:28}}>warning</Icon>
                            </div>
                            <div style={{width:"calc(100% - 60px)", height:60, display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:'center', padding:4}}>
                                <p style={{color:"#FFFFFF", fontSize:16, fontWeight:"bold", textAlign:"left", margin:0}}>
                                    {this.state.dangerZoneLE.dangerZone.textToDisplay}
                                </p>
                            </div>
                        </div>

                    </Paper>
                }
                <div style={{width:"100%", height:this.state.dangerZoneLE ? "calc(100vh - 134px)" : "calc(100vh - 84px)"}}>
                    {this.renderThermometer()}
                </div>

            </div>

        )
    }

    renderThermometer(){

        if(!this.isDesktop || this.state.thermometerStops.length === 0 || this.hideThermometer === true){return null}


        return(
            <div style={{width:"100%", height:"100%", overflow:"hidden",  backgroundColor:"rgba(255,255,255,0.65)", borderRadius:8,
            pointerEvents:"all", }} id={"stops-scroller"} className={"transparent-paper"}>
                {this.state.thermometerStops.map((stopPE,index)=>{

                    let prevColor = "#CCCCCC";
                    let mainElt = document.getElementById("main-container");
                    if(mainElt && mainElt.classList.contains("night-mode")){
                        prevColor = "#555555";
                    }

                    const previous = stopPE.previous === true;
                    const bgColor =  previous ? prevColor : "#1079FC"
                    const height = previous ? PAST_STOP_HEIGHT : NEXT_STOP_HEIGHT;

                    return(
                        <div style={{width:"100%", height:height, display:"flex", flexDirection:"row", justifyContent:"flex-start",
                        alignItems:'center', paddingLeft:8, position:"relative", marginBottom:stopPE.current ? PAST_STOP_HEIGHT : 0}}
                             key={"stop" + stopPE.stop.id}>

                            {index !== 0 &&
                                <div style={{height:height, position:"absolute", left:16, bottom:height/2, borderLeft:"1px solid #555555",
                                borderRight:"1px solid #555555", backgroundColor:bgColor, width:8, zIndex:10}}/>
                            }

                            {stopPE.current &&
                             <div style={{height:NEXT_STOP_HEIGHT, position:"absolute", left:16, top:height/2, borderLeft:"1px solid #555555",
                                borderRight:"1px solid #555555", backgroundColor:"#1079FC", width:8, zIndex:10}}/>
                            }

                            {stopPE.current &&
                                <Paper style={{height:30, width:30, backgroundColor:"#FFFFFF", borderRadius:7, justifyContent:"center", alignItems:'center',
                                    position:"absolute", zIndex:15, left:5, top:height - 4, display:"flex"}}>
                                    <Icon size={26} style={{color:"#1079FC"}} >navigation</Icon>
                                </Paper>
                            }

                            <div style={{height:24, width:24, borderRadius:"50%", border:"1px solid #555555", display:"flex", justifyContent:"center",
                            alignItems:'center', backgroundColor:bgColor, zIndex:20}} className={"stop-wrapper"}>
                                <div style={{height:10, width:10, borderRadius:"50%", border:"1px solid #555555", display:"flex", justifyContent:"center",
                                    alignItems:'center', backgroundColor:"#EFEFEF"}} className={"stop-center"}/>

                            </div>
                            <p style={{margin:0, textAlign:"left", flex:1, paddingLeft:8, color:"#222222", fontWeight:stopPE.current ? "bold" : "normal",
                            fontSize:stopPE.current ? 18 : 15}} className={"stop-name"}>
                                {stopPE.stop.name}
                            </p>



                        </div>
                    )
                })}
            </div>
        )
    }

    getThermometerStops(projection){
        let result = projection.previousStopsPE.slice().map((stop)=>{stop.previous = true; return stop});
        let nextStops = projection.nextStopsPE.slice();
        result = result.concat(nextStops);
        result = result.map((stop)=>{stop.current = false; return stop})

        for(let stop of result){
            if(stop.previous !== true){
                stop.current = true;
                break;
            }
        }

        result.reverse();

        return result;
    }

    scrollStops(){
        let elt = document.getElementById("stops-scroller");
        if(!elt){return}


        let pastStopsCount = 0;
        let nextStopCount = 0;
        for(let stop of this.state.thermometerStops){
            if(stop.previous === true){
                pastStopsCount ++;
            }
            else{
                nextStopCount ++;
            }
        }

        let eltHeight = elt.offsetHeight;


        let scrollTop = nextStopCount*NEXT_STOP_HEIGHT + 3*PAST_STOP_HEIGHT - eltHeight;
        elt.scrollTop = scrollTop;
    }
}
