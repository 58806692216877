let direction_roundabout = require('./assets/tbt_directions/Light/direction_roundabout.png');
let direction_roundabout_right = require('./assets/tbt_directions/Light/direction_roundabout_right.png');
let direction_roundabout_slight_right = require('./assets/tbt_directions/Light/direction_roundabout_slight_right.png');
let direction_roundabout_sharp_right = require('./assets/tbt_directions/Light/direction_roundabout_sharp_right.png');
let direction_roundabout_left = require('./assets/tbt_directions/Light/direction_roundabout_left.png');
let direction_roundabout_slight_left = require('./assets/tbt_directions/Light/direction_roundabout_slight_left.png');
let direction_roundabout_sharp_left = require('./assets/tbt_directions/Light/direction_roundabout_sharp_left.png');
let direction_roundabout_straight = require('./assets/tbt_directions/Light/direction_roundabout_straight.png');


let direction_turn_left = require('./assets/tbt_directions/Light/direction_turn_left.png');
let direction_turn_slight_left = require('./assets/tbt_directions/Light/direction_turn_slight_left.png');
let direction_turn_sharp_left = require('./assets/tbt_directions/Light/direction_turn_sharp_left.png');
let direction_turn_right = require('./assets/tbt_directions/Light/direction_turn_right.png');
let direction_turn_slight_right = require('./assets/tbt_directions/Light/direction_turn_slight_right.png');
let direction_turn_sharp_right = require('./assets/tbt_directions/Light/direction_turn_sharp_right.png');
let direction_uturn = require('./assets/tbt_directions/Light/direction_uturn.png');
let direction_turn_straight = require('./assets/tbt_directions/Light/direction_turn_straight.png');

let direction_arrive = require('./assets/tbt_directions/Light/direction_arrive.png');


let tooltip_roundabout = require('./assets/tbt_tooltips/tooltip_roundabout.png');
let tooltip_roundabout_right = require('./assets/tbt_tooltips/tooltip_roundabout_right.png');
let tooltip_roundabout_slight_right = require('./assets/tbt_tooltips/tooltip_roundabout_slight_right.png');
let tooltip_roundabout_sharp_right = require('./assets/tbt_tooltips/tooltip_roundabout_sharp_right.png');
let tooltip_roundabout_left = require('./assets/tbt_tooltips/tooltip_roundabout_left.png');
let tooltip_roundabout_slight_left = require('./assets/tbt_tooltips/tooltip_roundabout_slight_left.png');
let tooltip_roundabout_sharp_left = require('./assets/tbt_tooltips/tooltip_roundabout_sharp_left.png');
let tooltip_roundabout_straight = require('./assets/tbt_tooltips/tooltip_roundabout_straight.png');


let tooltip_turn_left = require('./assets/tbt_tooltips/tooltip_turn_left.png');
let tooltip_turn_slight_left = require('./assets/tbt_tooltips/tooltip_turn_slight_left.png');
let tooltip_turn_sharp_left = require('./assets/tbt_tooltips/tooltip_turn_sharp_left.png');
let tooltip_turn_right = require('./assets/tbt_tooltips/tooltip_turn_right.png');
let tooltip_turn_slight_right = require('./assets/tbt_tooltips/tooltip_turn_slight_right.png');
let tooltip_turn_sharp_right = require('./assets/tbt_tooltips/tooltip_turn_sharp_right.png');
let tooltip_uturn = require('./assets/tbt_tooltips/tooltip_uturn.png');
let tooltip_turn_straight = require('./assets/tbt_tooltips/tooltip_turn_straight.png');

let tooltip_arrive = require('./assets/tbt_tooltips/tooltip_arrive.png');

export class DirectionHelper {


    static getPictureSrc(index:number) {
        return DirectionHelper.navigation_dat[index].src;
    }

    static getTooltipSrc(index:number) {
        return DirectionHelper.navigation_dat[index].tooltip;
    }


    static navigation_dat =[
        {
            position: 0,
            description: "Continuer tout droit",
            tts:"Continuez tout droit",
            ttd:"Continuez tout-droit",
            src: direction_turn_straight,
            tooltip: tooltip_turn_straight
        },
        {
            position: 1,
            description: "Tourner légèrement à droite",
            tts:"Tournez à droite",
            ttd:"Tournez à droite",
            src: direction_turn_slight_right,
            tooltip: tooltip_turn_slight_right
        },
        {
            position: 2,
            description: "Tourner à droite",
            tts:"Tournez à droite",
            ttd:"Tournez à droite",
            src: direction_turn_right,
            tooltip: tooltip_turn_right
        },
        {
            position: 3,
            description: "Tourner fortement à droite",
            tts:"Tournez à droite",
            ttd:"Tournez à droite",
            src: direction_turn_sharp_right,
            tooltip: tooltip_turn_sharp_right
        },
        {
            position: 4,
            description: "Faire demi-tour",
            tts:"Faites demi-tour",
            ttd:"Faites demi-tour",
            src: direction_uturn,
            tooltip: tooltip_uturn
        },
        {
            position: 5,
            description: "Tourner fortement à gauche",
            tts:"Tournez à gauche",
            ttd:"Tournez à gauche",
            src: direction_turn_sharp_left,
            tooltip: tooltip_turn_sharp_left
        },
        {
            position: 6,
            description: "Tourner à gauche",
            tts:"Tournez à gauche",
            ttd:"Tournez à gauche",
            src: direction_turn_left,
            tooltip: tooltip_turn_left
        },
        {
            position: 7,
            description: "Tourner légèrement à gauche",
            tts:"Tournez à gauche",
            ttd:"Tournez à gauche",
            src: direction_turn_slight_left,
            tooltip: tooltip_turn_slight_left
        },
        {
            position: 8,
            description: "Au rond-point, continuer tout droit",
            tts:"Au rond-point, continuez tout droit",
            ttd:"Au rond-point, continuez tout-droit",
            src: direction_roundabout_straight,
            tooltip: tooltip_roundabout_straight
        },
        {
            position: 9,
            description: "Au rond-point, tourner légèrement à droite",
            tts:"Au rond-point, tournez à droite",
            ttd:"Au rond-point, tournez à droite",
            src: direction_roundabout_slight_right,
            tooltip: tooltip_roundabout_slight_right
        },
        {
            position: 10,
            description: "Au rond-point, tourner à droite",
            tts:"Au rond-point, tournez à droite",
            ttd:"Au rond-point, tournez à droite",
            src: direction_roundabout_right,
            tooltip: tooltip_roundabout_right
        },
        {
            position: 11,
            description: "Au rond-point, tourner fortement à droite",
            tts:"Au rond-point, tournez à droite",
            ttd:"Au rond-point, tournez à droite",
            src: direction_roundabout_sharp_right,
            tooltip: tooltip_roundabout_sharp_right
        },
        {
            position: 12,
            description: "Au rond-point, faire demi-tour",
            tts:"Au rond-point, faites demi-tour",
            ttd:"Faites demi-tour",
            src: direction_uturn,
            tooltip: tooltip_uturn
        },
        {
            position: 13,
            description: "Au rond-point, tourner fortement à gauche",
            tts:"Au rond-point, tournez à gauche",
            ttd:"Au rond-point, tournez à gauche",
            src: direction_roundabout_sharp_left,
            tooltip: tooltip_roundabout_sharp_left
        },
        {
            position: 14,
            description: "Au rond-point, tourner à gauche",
            tts:"Au rond-point, tournez à gauche",
            ttd:"Au rond-point, tournez à gauche",
            src: direction_roundabout_left,
            tooltip: tooltip_roundabout_left
        },
        {
            position: 15,
            description: "Au rond-point, tourner légèrement à gauche",
            tts:"Au rond-point, tournez à gauche",
            ttd:"Au rond-point, tournez à gauche",
            src: direction_roundabout_slight_left,
            tooltip: tooltip_roundabout_slight_left
        },
        {
            position: 16,
            needsExitNumber: true,
            exitNumber: null,
            description: "Au rond point:",
            tts: "Au rond-point",
            ttd: "Au rond-point",
            src: direction_roundabout,
            tooltip: tooltip_roundabout
        },
        {
            position:17,
            description: "Arrivée terminus",
            tts:"Allez jusqu'au terminus",
            ttd:"Allez jusqu'au terminus",
            src: direction_arrive,
            tooltip: tooltip_arrive
        }
    ];

    static getPictoCodeFromGoogleMapsManoeuver(manoeuver) {
        switch (manoeuver) {
            case "straight":
                return 0;
            case "turn-slight-right":
                return 1;
            case "turn-right":
                return 2;
            case "turn-sharp-right":
                return 3;
            case "uturn-right":
                return 4;
            case "uturn-left":
                return 4;
            case "turn-sharp-left":
                return 5;
            case "turn-left":
                return 6;
            case "turn-slight-left":
                return 7;
            case "roundabout-straight":
                return 16;
            case "roundabout-slight-right":
                return 16;
            case "roundabout-right":
                return 16;
            case "roundabout-sharp-right":
                return 16;
            case "roundabout-sharp-left":
                return 16;
            case "roundabout-left":
                return 16;
            case "roundabout-slight-left":
                return 16;
            default:
                return 0;
        }
    }

}
