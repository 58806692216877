import React,{Component, PureComponent} from 'react';
import "./../../App.css"
import {Paper, Icon} from "@material-ui/core"
import {FlexnavProjectionState} from "hubup-map-engine";
export default class NextStopFooter extends Component {

    constructor(props) {
        super(props);

        this.state={
            nextStop:null,
        }
    }

    _projectionUpdateSubscription:any;
    isDesktop:any = window.innerWidth >= 800;


    componentDidMount(){
        this._projectionUpdateSubscription = global.emitter.addListener("projectionUpdate",(projection)=>{
            this.handleProjection(projection)
        })
    }

    componentWillUnmount(){
        if(this._projectionUpdateSubscription){this.this._projectionUpdateSubscription.remove()}
    }

    handleProjection(projectionState:FlexnavProjectionState){

        if(!projectionState){
            return
        }

        let nextStop = null;

        if(projectionState.nextStopsPE && projectionState.nextStopsPE.length > 0){
            nextStop = projectionState.nextStopsPE[0].stop;
            nextStop.distanceTo = projectionState.distanceToNextStop;
            nextStop.reachPercentage = null;

            let prevStopDistFromOrigin = 0
            let nextStopDistFromOrigin = projectionState.nextStopsPE[0].distanceFromOrigin;



            if(projectionState.previousStopsPE && projectionState.previousStopsPE.length > 0){
                prevStopDistFromOrigin = projectionState.previousStopsPE[projectionState.previousStopsPE.length - 1].distanceFromOrigin
            }

            let distBetweenStops = nextStopDistFromOrigin - prevStopDistFromOrigin;
            let percentage = (distBetweenStops - projectionState.distanceToNextStop)/distBetweenStops

            nextStop.reachPercentage = Math.max(percentage,0.01)*100;

        }

        this.setState({
            nextStop:nextStop
        })
    }

    render(){
        if(!this.state.nextStop){return null}

        return (
            <div style={{marginBottom:8, marginLeft:this.isDesktop ? 300 : 0, minHeight:60,
                width:500, maxWidth:"calc(100vw - 16px)", pointerEvents:"all", display:"flex", justifyContent:"flex-end", alignItems:'center',
                flexDirection:"column", paddingLeft:25, paddingRight:25}}>

                {this.state.nextStop.name &&
                    <div style={{paddingLeft:10, paddingRight:10, minHeight:30, borderRadius:20, backgroundColor:"rgba(0,0,0,0.7)",
                    display:"flex", justifyContent:"center", alignItems:'center', flexDirection:"row"}}>
                        <Icon size={16} style={{color:"#FFFFFF", marginRight:8}}>directions_bus</Icon>
                        <p style={{color:"#FFFFFF", fontSize:14, textAlign:"left", fontWeight:"normal", width:"100%", margin:0, paddingRight:4}}>
                            {this.state.nextStop.name.toUpperCase()}
                        </p>
                    </div>
                }

                <Paper style={{backgroundColor:"#FFFFFF", height:30, width:"100%", borderRadius:8, marginTop:8}} className={"progress-stop-paper"}>
                    {this.state.nextStop && this.state.nextStop.reachPercentage &&
                    <div style={{height:"100%", width:"100%", borderRadius:10, justifyContent:"flex-start", alignItems:'center', flexDirection:"row",
                        position:"relative", padding:10, display:"flex"}}>
                        <div style={{height:8, backgroundColor:'#DDDDDD', width:this.state.nextStop.reachPercentage + "%", borderTopLeftRadius:2, borderBottomLeftRadius:2}} />
                        <div style={{flex:1,height:8, backgroundColor:"#1079FC", borderTopRightRadius:2, borderBottomRightRadius:2}} />

                        <div style={{height:8, width:30, backgroundColor:"#DDDDDD", zIndex:6, position:"absolute",
                            left:this.state.nextStop.reachPercentage + "%", top:11, marginLeft:-15}}/>

                        <Paper style={{height:30, width:30, backgroundColor:"#FAFAFA", borderRadius:7, justifyContent:"center", alignItems:'center',
                             position:"absolute", zIndex:15, left:this.state.nextStop.reachPercentage + "%", top:0, marginLeft:-5, display:"flex"}}>
                            <Icon size={26} style={{color:"#1079FC"}} className={"rotated90"}>navigation</Icon>
                        </Paper>

                    </div>
                    }
                </Paper>

            </div>
        )
    }
}
