import React, {Component} from 'react';
import './Misc.css';
import {Paper, Icon} from "@material-ui/core";

export default class SnackBar extends Component {

    constructor(props) {
        super(props);

        this.state={
            displayed:false,
            type:"",
            body:"",
        }
    }

    _hideTimeout:any;

    componentDidMount(){

        global.showSnackBar = (data) => {
            this.onShouldShowSnackBar(data)
        }
    }


    onShouldShowSnackBar(data){

        if(this._hideTimeout){clearTimeout(this._hideTimeout)}

        let type = "info";
        if(data.type){type = data.type}

        let body = "";
        if(data.body){body = data.body};

        this.setState({
            displayed:true,
            type:type,
            body:body,
        })
        this._hideTimeout = setTimeout(()=>{
            this.setState({displayed:false})
        },5000)
    }

    render(){

        let color = "#1079FC";
        let icon = "info"

        if(this.state.type === "success"){
            color = "#2CBE4E";
            icon = "check_circle"
        }
        else if (this.state.type === "error"){
            color = "#EE3E3B";
            icon = "cancel"
        }

        return(
            <div className={this.state.displayed ? "snackbar-wrapper snackbar-wrapper-translated" : "snackbar-wrapper"}>
                <Paper className={"snackbar-paper"}>
                    <div className={"snackbar-icon-wrapper"} style={{backgroundColor:color}}>
                        <Icon className={"snackbar-icon"}>{icon}</Icon>
                    </div>
                    <p className={"snackbar-text"}>
                        {this.state.body}
                    </p>
                </Paper>
            </div>
        )
    }

}
