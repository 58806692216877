import React, {Component} from 'react';
import {FlexnavDriver, FlexnavEngineProperties, FlexnavProjectionState, ConvertiblePosition} from "hubup-map-engine";
import './App.css';

import {Paper, Icon} from "@material-ui/core";

import {Tools} from "./Tools";
import {EventEmitter} from 'fbemitter';
import DirectionHeader from "./components/DirectionHeader/DirectionHeader";
import {DirectionHelper} from "./DirectionHelper";
import NextStopFooter from "./components/NextStopFooter/NextStopFooter";
import SnackBar from "./components/Misc/SnackBar";

export default class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            geolocationButtonDisplayed:false,
            geolocationButtonHiddenFromUrl:false,
            isGeolocating:false,
        }

        global.emitter = new EventEmitter();
        this.onMessageReceived = this.onMessageReceived.bind(this);
    }

    map: any;

    _latLng = {
        lat: 48.8534,
        lng: 2.3488
    }

    _pitch = 0;
    _bearing = 0;

    _driver: FlexnavDriver = new FlexnavDriver();
    _engineProperties: FlexnavEngineProperties;

    _stopRefs=[];

    _currentPositionMarker:any;
    _geolocationEnabled:boolean=false;

    isDesktop:any = window.innerWidth >= 800;

    componentDidMount() {
        this.initConfiguration();
    }

    initConfiguration() {
        this.initMap()
            .then(() => {
                return this.getData()
            })
            .then((dataSet) => {
                if(this._geolocationEnabled === true){
                    this.setState({
                        geolocationButtonDisplayed:true,
                        isGeolocating:false,
                    })
                }

                let parameters = {autoAdjustDensity: false, desiredDensity: 20, disable_away_check: true};
                return this._driver.initFromDataset(dataSet, parameters)
            })
            .then(() => {
                let ep =  this._driver.getActiveEngineProperties();

                if(!ep.stopsPunctualElements){ep.stopsPunctualElements = []}
                if(!ep.directionsPunctualElements){ep.directionsPunctualElements = []}
                if(!ep.dangerZoneLinearElements){ep.dangerZoneLinearElements = []}

                this._engineProperties = ep;

                // console.log("Init done", this._engineProperties);

                this.drawMainPolyline();
                this.drawDangerZonesPolylines();
                this.drawStops();

                this.sendMessageToParent("mapReady")

                setTimeout(()=>{
                    this.jumpToCoordinate(this._engineProperties.computedBounds);

                    if(this.isDesktop){
                        this.drawBuildings();
                    }

                },1000)


            })
            .catch((err) => {
                console.log(err);
                global.showSnackBar({
                    type:"error",
                    body:"Une erreur est survenue lors du chargement des données"
                })
                // alert("Une erreur est survenue lors du chargement des données")
            })
    }

    _geolocatorId:any;

    startGeolocation(){
        this.setState({
            isGeolocating:true,
        },()=>{
            let options = {
                enableHighAccuracy: true,
                timeout: 1000,
                maximumAge: 0
            };
            this._geolocatorId = navigator.geolocation.watchPosition((pos)=>{
                let posistion = {
                    lat:pos.coords.latitude,
                    lng:pos.coords.longitude
                }
                this.projectPosition(posistion);
            }, (err)=>{
                console.log(err);
            }, options);
        })
    }

    stopGeolocation(){
        this.setState({
            isGeolocating:false,
        },()=>{
            navigator.geolocation.clearWatch(this._geolocatorId);
        })
    }

    toggleGeolocation(){
        if(this.state.isGeolocating){this.stopGeolocation()}
        else{this.startGeolocation()}
    }

    getData() {
        let urlParams = new URLSearchParams(window.location.search);
        let server = urlParams.get("server");
        let apiKey = urlParams.get("apiKey")
        let bearer = urlParams.get("bearer")
        let track = urlParams.get("track")
        let tripId = urlParams.get("tripId")
        let geolocation = urlParams.get("geolocation")
        let hideStartButton = urlParams.get("hidestartbutton")

        let promise;

        if(server && (apiKey || bearer)){
            promise = this.getDistantDataPromise(server,apiKey,track,tripId,bearer)
        }
        else{
            promise = new Promise((resolve,reject) => {reject("Server and API key are mandatory")})
        }

        if(geolocation && geolocation === "enabled"){
            this._geolocationEnabled = true
        }

        if(hideStartButton && hideStartButton === "true"){
            this.setState({
                geolocationButtonHiddenFromUrl:true,
            })
        }

        return promise;
    }

    getDistantDataPromise(server,apiKey,track,tripId,bearer){

        let serverUrl = "https://" + server  + ".api.hubup.fr"

        let url = "";
        if(track){
            if(apiKey){
                url = serverUrl + "/api2/track/driving/data/from/" + track + "/track";
            }
            else{
                url = serverUrl + "/api/geotracking/tracks/" + track + "/edit/data";
            }
        }
        else if(tripId){
            url = serverUrl + "/api2/track/driving/data/from/" + tripId + "/trip/id";
        }
        else{
            url = serverUrl + "/404"
        }

        let headers;

        if(apiKey){
            headers = new Headers({"Authorization": "ApiKey " + apiKey});
        }
        else if(bearer){
            headers = new Headers({"Authorization": "Bearer " + bearer});
        }


        return new Promise((resolve,reject)=>{

            fetch(url,{
                method:"GET",
                headers:headers
            })
                .then((response)=>{
                   return response.json()
                })
                .then((trackData)=>{
                    resolve(trackData)
                })
                .catch((err)=>{
                    reject(err)
                })
        })
    }

    _nightModeEnabled:boolean;

    toggleNightMode(){
        let elt = document.getElementById("main-container");
        let dayStyle = "mapbox://styles/quentinor/ck84n2ixf02hm1jqk3svyox48?optimize=true";
        let nightStyle = "mapbox://styles/quentinor/cke9vwyv95r7m1arq2s58i67t?optimize=true";

        if(this._nightModeEnabled === true){
            elt.classList.remove("night-mode");
            this._map.setStyle(dayStyle);
        }
        else{
            elt.classList.add("night-mode")
            this._map.setStyle(nightStyle);
        }
        this._nightModeEnabled = !this._nightModeEnabled;
    }

    onMessageReceived(event) {
        if(event.data){
            if(event.data === "toggleFlexnavPWANightMode"){
                this.toggleNightMode();
            }
            else if(event.data === "enableFollowMyLocation"){
                if(!this.state.isGeolocating){this.startGeolocation()}
            }
            else if(event.data === "stopFollowMyLocation"){
                if(this.state.isGeolocating){this.stopGeolocation()}
            }
        }
    }

    sendMessageToParent(message){
        if(window.parent){
            window.parent.postMessage(message,"http://localhost:3000/")
        }
        else{
            console.log("No parent detected");
        }
    }

    initMap() {

        return new Promise((resolve) => {
            let mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
            mapboxgl.accessToken = 'pk.eyJ1IjoicXVlbnRpbm9yIiwiYSI6ImNrODRrbHkzYTBsZDMzZm9kdzZhcDE5NnoifQ.Hp1u68dU_jdwZkQirrD-RQ';

            let urlParams = new URLSearchParams(window.location.search);
            let nightMode = urlParams.get("nightmode");

            let url = "mapbox://styles/quentinor/ck84n2ixf02hm1jqk3svyox48";
            if(nightMode && nightMode === "enabled"){
                this._nightModeEnabled = true;
                url="mapbox://styles/quentinor/cke9vwyv95r7m1arq2s58i67t?optimize=true";
                let elt = document.getElementById("main-container");
                if(elt){
                    elt.classList.add("night-mode")
                }
            }

            window.addEventListener("message", this.onMessageReceived, false);

            this._map = new mapboxgl.Map({
                container: 'map',
                style: url,
                center: [this._latLng.lng, this._latLng.lat],
                pitch: this._pitch,
                bearing: this._bearing,
                zoom: 15
            });

            this._map.on("load", () => {
                resolve()
            })

            this._map.on("click", (event) => {
                this.onMapClicked(event);
            })
        })

    }

    onMapClicked(event){
        this.sendMessageToParent("flexnavPWAMapClicked");
        if(this._geolocationEnabled === true){
            //Do nothing, geolocation is enabled
        }
        else{
            let position = event.lngLat
            this.projectPosition(position)
        }
    }

    projectPosition(position){

        let mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');

        if(!this._driver){
            return
        }

        this._driver.onNewPositionReceived(new ConvertiblePosition(position.lat, position.lng))
            .then((projectionState:FlexnavProjectionState)=>{

                //Emit for subcomponents
                global.emitter.emit("projectionUpdate",projectionState)

                if(projectionState && projectionState.qualifiedProjection){

                    if(projectionState.tts){
                        this.speak(projectionState.tts)
                    }

                    this.handleDirectionMarker(projectionState)

                    let newPos = projectionState.qualifiedProjection.refitPosition;

                    this._latLng = {
                        lat:newPos.lat,
                        lng:newPos.lng,
                    }
                    this._pitch = 100;
                    this._bearing = newPos.heading;

                    this._map.easeTo({
                        center: this._latLng,
                        pitch: this._pitch,
                        bearing: this._bearing,
                        zoom:19,
                    });

                    if(!this._currentPositionMarker){

                        let el = document.createElement('current-position');
                        el.className = 'current-position-marker';

                        this._currentPositionMarker = new mapboxgl.Marker({
                            element:el,
                            color:"#EE3E3B",
                            pitchAlignment:"map",
                            rotationAlignment:"map",
                            rotation:this._bearing,
                        })
                            .setLngLat([this._latLng.lng, this._latLng.lat])
                            .addTo(this._map);
                    }
                    else{
                        this._currentPositionMarker
                            .setLngLat([this._latLng.lng, this._latLng.lat])
                            .setRotation(this._bearing)

                    }
                }
            })
    }

    _nextDirectionMarker:any;

    handleDirectionMarker(projectionState:FlexnavProjectionState){


        if(!projectionState){return;}

        let mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');

        if(projectionState.nextDirectionPE){

            let el = document.createElement('next-direction');
            if(el){
                el.className = 'next-direction-marker';
                el.style.backgroundImage = "url(" +  DirectionHelper.getTooltipSrc(projectionState.nextDirectionPE.direction.position) + ")"
            }

            if(!this._nextDirectionMarker){
                this._nextDirectionMarker = new mapboxgl.Marker({
                    element:el,
                    anchor:"bottom-right",
                })
                    .setLngLat([
                        projectionState.nextDirectionPE.projectedCP.lng,
                        projectionState.nextDirectionPE.projectedCP.lat
                    ])
                    .addTo(this._map)
            }
            else{
                this._nextDirectionMarker.setLngLat([
                    projectionState.nextDirectionPE.projectedCP.lng,
                    projectionState.nextDirectionPE.projectedCP.lat
                ])

                let el =  document.getElementsByTagName('next-direction')[0];
                if(el){
                    el.style.backgroundImage = "url(" +  DirectionHelper.getTooltipSrc(projectionState.nextDirectionPE.direction.position) + ")"
                }
            }
        }
        else{
            if(this._nextDirectionMarker){
                try{this._nextDirectionMarker.remove()}
                catch(e){console.log(e)}
            }
        }
    }

    speak(tts){
        let msg = new SpeechSynthesisUtterance();
        msg.voiceURI = 'native';
        msg.volume = 1; // 0 to 1
        msg.rate = 1; // 0.1 to 10
        msg.text = tts;
        msg.lang = 'fr-FR';

        speechSynthesis.cancel()

        setTimeout(()=>{
            speechSynthesis.speak(msg);
        },10)

    }

    fitMapToBounds(bounds) {
        try {
            this._map.fitBounds([
                [bounds.minLng, bounds.minLat],
                [bounds.maxLng, bounds.maxLat]
            ]);
        } catch (e) {
            console.log(e)
        }
    }

    jumpToCoordinate(bounds){
        try {
            this._map.jumpTo({
                center:[(bounds.minLng+bounds.maxLng)/2, (bounds.minLat + bounds.maxLat)/2],
                zoom:13,
            });
        } catch (e) {
            console.log(e)
        }
    }

    drawMainPolyline(){
        if(!this._engineProperties){return}
        let geoJSONPolyline = Tools.polylineToGeoJSON(this._engineProperties.positions);

        this._map.addSource('mainPolyline',geoJSONPolyline);

        this._map.addLayer({
            id: 'mainPolylineOutline',
            type: 'line',
            source: 'mainPolyline',
            layout: {'line-join': 'round', 'line-cap': 'round'},
            paint: {
                'line-color': '#222222',
                'line-width': 12
            }
        });

        this._map.addLayer({
            id: 'mainPolyline',
            type: 'line',
            source: 'mainPolyline',
            layout: {'line-join': 'round', 'line-cap': 'round'},
            paint: {
                'line-color': '#1079FC',
                'line-width': 8
            }
        });
    }

    drawDangerZonesPolylines(){
        if(!this._engineProperties){return}

        let incrementor = 1;
        for(let dle of this._engineProperties.dangerZoneLinearElements){

            let geoJSONPolyline = Tools.polylineToGeoJSON(dle.positions);

            let ref = 'dangerZonePolyline_' + incrementor;

            this._map.addSource(ref,geoJSONPolyline);

            this._map.addLayer({
                id: ref,
                type: 'line',
                source: ref,
                layout: {'line-join': 'round', 'line-cap': 'round'},
                paint: {
                    'line-color': '#fc8a10',
                    'line-width': 8
                }
            });

            incrementor ++;
        }
    }

    drawStops(){
        let mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
        if(!this._engineProperties){return}
        for(let stop of this._engineProperties.stops){

            let el = document.createElement('stop-marker');
            el.className = 'bus-stop-marker';

            let marker = new mapboxgl.Marker({
                element:el,
                color:"#0CBCB7"
            })
                .setLngLat([stop.longitude, stop.latitude])
                .addTo(this._map);
            this._stopRefs.push(marker);
        }
    }

    drawBuildings(){
        this._map.addLayer(
            {
                'id': '3d-buildings',
                'source': 'composite',
                'source-layer': 'building',
                'filter': ['==', 'extrude', 'true'],
                'type': 'fill-extrusion',
                'minzoom': 15,
                'paint': {
                    'fill-extrusion-color': '#aaa',
                    'fill-extrusion-height': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        15,
                        0,
                        15.05,
                        ['get', 'height']
                    ],
                    'fill-extrusion-base': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        15,
                        0,
                        15.05,
                        ['get', 'min_height']
                    ],
                    'fill-extrusion-opacity': 0.4
                }
            }
        )
    }

    render() {
        return (
            <div style={{
                width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: 'center', backgroundColor: '#FAFAFA',
                flexDirection: "column"
            }} id={"main-container"}>


                <SnackBar/>

                <div style={{height: "100%", width: "100%",}}>
                    <div style={{height: "100%", width: "100%", overflow: "hidden", position:"relative"}}>
                        <div id={"map"} style={{height: "150%", width: this.isDesktop ? "calc(100% + 300px)" : "100%"}}/>

                        <div style={{height:"100%", width:"100%", position:"absolute", left:0, top:0, bottom:0, right:0, zIndex:200,
                        pointerEvents:"none"}}>
                            <DirectionHeader/>
                        </div>

                        <div style={{height:"100%", width:"100%", position:"absolute", left:0, top:0, bottom:0, right:0, zIndex:190,
                        pointerEvents:"none", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-end"}}>
                            <NextStopFooter/>
                        </div>

                        {this.state.geolocationButtonDisplayed && !this.state.geolocationButtonHiddenFromUrl &&
                            <Paper style={{position:"absolute", right:8, bottom:this.state.isGeolocating ? 48 : 8, height:50, width:50, borderRadius:"50%", zIndex:500,
                            display:"flex", justifyContent:"center", alignItems:'center', backgroundColor:"#1079FC"}}
                            onClick={()=>{this.toggleGeolocation()}}>

                                <Icon style={{color:"#FFFFFF", fontSize:26}}>
                                    {this.state.isGeolocating ? "pause" : "play_arrow"}
                                </Icon>
                            </Paper>
                        }

                    </div>
                </div>

            </div>
        );
    }
}
