export class Tools{

    static polylineToGeoJSON(decodedPolyline){
        let coordinates = decodedPolyline.map((position)=>{
            return([position.lng, position.lat])
        })
        return({
            type: 'geojson',
            data: {
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'LineString',
                    coordinates: coordinates
                }
            }
        })
    }

    static getPrettyDistance(dist){
        let unit = dist < 1000 ? "m" : "km";
        if(dist < 1000){
            let meters = Math.round(dist/10)*10;
            return meters + ' ' + unit;
        }
        else{
            let kilometers = Math.round(dist/100)/10;
            return kilometers + ' ' + unit
        }
    }
}
